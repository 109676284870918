<template>
    <div>
        <ResultadoForm
            :title="title"
            :headerTitle="headerTitle"
            :urlRead="urlRead"
            :urlCreate="urlCreate"
            :urlUpdate="urlUpdate"
            :item="item"
            v-if="mode === 2"
        />
        <ResultadoLegacyForm
            :title="title"
            :headerTitle="headerTitle"
            :urlRead="urlRead"
            :urlCreate="urlCreate"
            :urlUpdate="urlUpdate"
            :item="item"
            v-if="mode === 1"
        />
    </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue';
import ResultadoForm from './ResultadoForm.vue';
import ResultadoLegacyForm from '../ResultadoLegacy/ResultadoLegacyForm.vue';

const mode = ref(1);

const props = defineProps({
    title: { type: String, default: 'Resultado de Examen' },
    headerTitle: { type: String, default: 'Crear Resultado' },
    urlRead: { type: String },
    urlCreate: { type: String, required: false },
    urlUpdate: { type: String, required: false },
    item: Object,
});

onMounted(() => {
    if (props.item) {
        mode.value = props.item.mode;
    }
});
</script>

<style></style>
